.notifybanner{
  background-color: $tertiary;
  color: #ffffff;
  display: inline-block;
  padding: 10px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.topthemen {
  .card{
    background-color: $tertiary;
    .btn-primary{
      background-color: $tertiary;
    }
  }
}


.tothepostslink{
  text-align: center;
  .btn{
    background-color: $primary;
    border-color: $primary;
    color: $secondary;
    padding: 6px 15px 6px 25px;
    font-weight: bold;
    border-radius: 4px;
    .gimmemyarrow{
      margin-left: 20px;
    }
    &:hover{
      background-color: $tertiary;
    }
  }
}

.gimmemyarrow{
  margin-left: 5px;
  width: 17px;
  height: 14px;
  stroke: $secondary;
}

.terminewidget{
  background: rgb(175,156,114);
  background: linear-gradient(45deg, rgba(175,156,114,1) 0%, rgba(237,233,224,1) 90%);

  .tothepostslink{
    .btn{
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
  }
}

.logobalken{
  background: rgb(175,156,114);
  background: linear-gradient(45deg, rgba(175,156,114,1) 0%, rgba(237,233,224,1) 90%);
  height: 80px;
  display: flex;
  justify-content: flex-end;
  img{
    width: 220px;
    margin: 5px;
  }
}
.logobalken2{
  height: 80px;
  display: flex;
  justify-content: flex-end;
  img{
    width: 280px;
    margin: 5px;
  }
}

.goldbalken{
  background: rgb(175,156,114);
  background: linear-gradient(45deg, rgba(175,156,114,1) 0%, rgba(237,233,224,1) 90%);
  height: 75px;
}

#bloginfobox{
  .container{
    background-color: $primary;
    padding: 0;

    .texte *{
      color: $secondary;
    }

    .designimg{
      width: 100%;
      height: auto;
      min-height: 355px;
      background-image: url("images/externeblogs.jpg");
      background-position: left bottom;
      background-repeat: no-repeat;
    }
  }
}

.sil_rss_widget_list{
  list-style: none;
  li{
    border-bottom: solid 1px $quaternary;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 18*$px-base;
    font-weight: normal;

    strong{
      font-weight: normal;
    }
    .date{
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16*$px-base;
    }
    .titel, .titel a{
      font-weight: normal;
      font-size: 22*$px-base;
      color: $primary;
    }
    .mehrlink{
      color: $primary;
      font-weight: bold;
      font-size: 14*$px-base;
      text-decoration: underline;
    }
    .blogname{
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  li:nth-last-child(1){
    border: none;
  }
}

.beitragspagenavi{
  background-color: $primary;
  margin-top: 50px;
  div{
    text-align: center;

    a{
      color: $secondary;
    }
  }
}