.home-page{
  .header{
    //min-height: 665px;
  }
}

.page-template-regionews .header{
  //background-image: url("images/header/nahe.jpg");
}

.header{


  .navbar{
    div{
      text-align: center;
      a, button{
        color: $secondary;
        text-transform: uppercase;
        font-size: 12*$px-base;
      }
    }
  }

  .headline {
    text-align: center;
    padding-top: 80px;
    @include media-breakpoint-down(sm){
      img{
        width: 300px;
      }
    }

    *{
      color: $secondary;
    }
    h1{
      text-align: center;
      font-size: 30*$px-base;
      line-height: 2rem;

      span{
        font-weight: bold;
        font-size: 21*$px-base;
        text-transform: uppercase;
      }
    }
  }

  .headlineheader{
    min-height: 100px;
    *{
      color: $secondary;
    }
    h1{
      font-weight: normal;
    }
    .date{
      font-weight: bold;
    }
    @include media-breakpoint-down(sm){
      h1{
        font-size: 21*$px-base;
      }
    }
  }
}

.slimheader{
  transition: 300ms height;
  background-color: $secondary;
  z-index: 400;
}

html body .slimheaderpostpages{
  .logo {
    a, button {
      color: $secondary;
    }
  }
  .slimheadernav{
    z-index: 300;
    a{
      color: $secondary;
    }
  }

  .navbar-toggler{
    background-image: url("images/icons/header/menu.png");
    span{
      color: $secondary;
    }
  }
}

.slimheader, .slimheaderpostpages{
  overflow:hidden;
  width: 100%;

  &.onmobile{
    .goldbalken{
      height: 80px !important;
    }
    .logo {
      @include media-breakpoint-down(xs) {
        display: flex !important;

        a{
          font-size: 21*$px-base;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      display: none !important;
    }
  }
  @include media-breakpoint-down(xs) {
    &.ondesktop {
      display: none !important;
    }
  }

  .logo {
    @include media-breakpoint-up(lg) {
      margin-left: 25px;
    }
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
    a, button {
      color: $primary;
    }
    h1 {
      text-align: center;
      font-size: 18*$px-base;
      font-weight: bold;
      a{
        font-weight: bold;
      }
      span {
        font-weight: normal;
        font-size: 12*$px-base;
        text-transform: uppercase;
      }
    }
  }
  .logorlp{
    img{
      width: 280px;
      margin: 5px;
    }
  }
  &.logobox{
    justify-content: space-between;
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  .slimheadernav{
    div{
      text-align: center;
    }
    a{
      font-weight: normal;
      text-transform: none;
      font-size: 12*$px-base;
      line-height: 1rem;
      img{
        width: 50px;
        @include media-breakpoint-up(md) {
          width: 40px;
        }
      }
    }
    button span{
      color: $primary;
    }
  }

  &.open{
    height: 92px;
  }

  .navbar-toggler{
    background-image: url("images/icons/header/menu_blau.png");
    width: 40px;
    border:none;
    border-radius: 0;
    background-repeat: no-repeat;
    color: $secondary;
    text-transform: uppercase;
    font-size: 12*$px-base;
    font-weight: bold;
    position: relative;
    padding: 32px 0px 0px 0px;
    span{
      display: block;
      padding-top: 10px;
      width: 40px;
      position: relative;
    }
  }

  &.menuopen{
    height: 92px;
    .navbar-toggler{
      background-image: url("images/icons/header/schliessen.png");
    }
  }
}

.breadcrumbbar{
  color: #4d4d4d;
  font-weight: normal;
  a{
    color: #4d4d4d;
    font-weight: normal;
  }
  span{
    color: $primary;
    font-weight: bold;
  }
}

.poweredbyrptbar{
  background-color: #e4ded0;
  padding: 30px;

  .inhalt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .text{
      padding-right: 30px;
    }
  }
}

@media (min-width: 768px) {
  .slimheader, .slimheaderpostpages{
    .logo {
      h1 {
        font-size: 27*$px-base;
        line-height: 1.2rem;
        padding-top: 15px;
        span {
          font-weight: normal;
          font-size: 14*$px-base;
          text-transform: uppercase;
        }
      }
    }
  }
}

.titlebalken{
  height: 200px;
  background-color: #e3d1c1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    color: $tertiary;
    font-weight: bold;
    font-size: 35*$px-base;
    @include media-breakpoint-down(xs) {
      font-size: 25*$px-base;
    }
  }
}