.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    line-height: 2rem;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.footer-gold{
  background: rgb(175,156,114);
  background: linear-gradient(45deg,#ab8767,#ab8767 10%,#ab8767 12%,#ac8868 41%,#b28f6f 52%,#bd9a7b 59%,#cdab8c 66%,#e1c0a2 71%,#f9d9bc 75%,#f7d7ba 76%,#e0bea1 82%,#d0ac90 87%,#c6a185 93%,#c39e82);
}