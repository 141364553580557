/* -----------------------------------------
   NEXT FORMS
----------------------------------------- */

#nex-forms .row{
  margin: 0 !important;
}

input[type=checkbox]{
  width: 50px !important;
}

.wpcf7-form input[type="radio"], .wpcf7-form input[type="checkbox"]{
  width: inherit !important;
}

.wpcf7-form input.anzahlfeld{
  width: 45px !important;
}

.wpcf7-form input[type="text"], .wpcf7-form input[type="email"]{
  width: 100%;
  padding: 10px;
}

.wpcf7 .wpcf7-checkbox label{
  display: block;
  font-weight: normal;
}

.wpcf7 .anzahlfeld {
  width: 30px;
}

.wpcf7 label{
  width: 100%;
}