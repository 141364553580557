.slick{
  padding-left: 0px;
  margin-bottom: 60px !important;
  li{
    .row{
      margin: 0;
      .col-12{
        background-size: cover;
        min-height: 470px;
        padding: 0px;
        background-position: center;
        .row{
          height: 100%;
          justify-content: flex-end;
        }
        .contentbox{
          &>div{
            background-color: $quaternary_light;
            border-radius: 7px;
            padding: 25px;
            max-height: 450px;
            font-weight: bold;
            font-size: 18*$px-base;
            margin: 100px 55px;
            @include media-breakpoint-down(md) {
              font-size: 18*$px-base;
              margin: 100px 20px;
            }
          }
          h3{
            font-weight: bold;
            font-size: 30*$px-base;
            margin-bottom: 15px;
          }

          .button{
            background-color: $tertiary;
            &:hover{
              background-color: $tertiaryhover;
            }
          }
        }
      }
    }
  }
  .slick-dots{
    bottom: -45px;
    li button:before, li.slick-active button:before{
      font-size: 16px;
      color: $primary;
    }
  }
}

.slick3er{
  padding: 0px 50px;
  @include media-breakpoint-down(xs) {
    margin-bottom: 50px;
    padding: 0px;
    .slick-track{
      margin-bottom: 30px;
    }
  }
  .row{
    margin: 0px 10px;
    display: block;
  }
  .slick-arrow{
    height: 45px;
    width: 45px;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    border-radius: 45px;
    background-size: 25px 25px;
    background-position: center;
    &.slick-next, &.slick-next:hover{
      background-image: url('images/icons/pfeil-slider-right.svg');
      height: 45px;
      width: 45px;
      background-repeat: no-repeat;
      border-radius: 45px;
      background-size: 25px 25px;
      background-position: center;
    }
    &.slick-next:hover, &.slick-prev:hover{
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.8);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.8);
    }
    &.slick-prev, &.slick-prev:hover{
      background-image: url('images/icons/pfeil-slider-links.svg');
      height: 45px;
      width: 45px;
      background-repeat: no-repeat;
      border-radius: 45px;
      background-size: 25px 25px;
      background-position: center;
    }
    &.slick-prev,&.slick-prev:hover{
      background-position: 7px;
    }
    &::before{
      content: '';
    }
    @include media-breakpoint-down(xs) {
      bottom: -40px;
      top: inherit;

      &.slick-prev{
        left: 95px;
      }

      &.slick-next{
        right: 95px;
      }
    }
  }
}