
/* -----------------------------------------
   MEDIA QUERIES
----------------------------------------- */

.navigation {
  display: none;
}

.logocol img {
  display: none;
}

.headerlasche, .lascherepeat {
  display: none;
}

@media (min-width: 400px) {
  #recaptcha_area, #recaptcha_table {
    margin-left: -3px;
  }
  .page-id-56823 table{
    display: table;
  }
  .page-id-56823 tr{
    display: table-row;
  }
  .page-id-56823 td{
    display: table-cell;
  }
  .page-id-56823 .table td:before {
    position: inherit;
    width: inherit;
    white-space: inherit;
  }
}

@media (min-width: 570px) {

  .postimagethumbcutter {
    overflow: hidden;
    height: 401px;
  }
}

@media screen and (max-width: 600px){
  #wpadminbar {
    position: fixed !important;
  }
}

@media (min-width: 650px) {

  .top-block, .lascherepeat {
    display: block;
  }
}

@media (min-width: 768px) {

  .postimagethumbcutter {
    height: 190px;
  }

  .innercontentborder {
    padding: 30px !important;
    padding-left: 60px !important;
    border-left: solid 1px #005a9b;
  }

  .navicol {
    border-top: none !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

}

@media (min-width: 991px) {

  .backstretch {
    display: block;
  }

  .aa_loginForm {
    width: 40%;
  }

  .postimagethumbcutter {
    height: 123px;
  }

  .navigation {
    display: block;
  }

  .MobileMenu {
    display: none;
  }

  .logocol img {
    display: none;
  }

  .lasche {
    width: 800px !important;
  }

  .read-more {
    float: right;
  }

  .tags-list {
    float: left;
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .logocol img {
    display: block;
  }

  .container {
    width: 1270px !important;
  }

  .postimagethumbcutter {
    height: 220px;
  }
}

.wdm_ul {
  width: 100%;
  left: 0;
}